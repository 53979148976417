import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import AllApp from "../Table/AllApp";
import Navbar from "../navbar/Navbar";
import Game from "../Table/Game";
import MovedApp from "../Table/MovedApp";
import MoreApp from "../Table/MoreApp";
import EditApp from "./EditApp";
import CustomAd from "../Table/CustomAd";
import Developer from "../Table/Developer";
import Profile from "../Page/Profile";
import ShortVideo from "../Table/ShortVideo";
import LiveStreamVideo from "../Table/LiveStreamVideo";
import VideoCallVideo from "../Table/VideoCallVideo";
import PrivateVideo from "../Table/PrivateVideo";
import TestId from "../Table/TestId";
import PostTable from "../Table/PostTable";
import NotificationPage from "./NotificationTable/NotificationPage";
import NotificationPageNotificationPage from "./NotificationPage";
import Error404 from "../extra/Error404";
import HostTable from "../Table/HostTable";
import GiftMain from "./GiftPage/GiftMain";
import HostTableTwo from "../Table/HostTableTwo";
import Banner from "./Banner";
import Community from "../Table/Community";
import WithdrawRequest from "./EditApp/WithdrawRequest";
import { CallHistory } from "./EditApp/CallHistory";
import { UserCallHistory } from "./EditApp/UserCallHistory";

export default function Admin() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname == "/" ||
      location.pathname == "/admin" ||
      location.pathname == "/admin/" ||
      location.pathname == ""
    ) {
      // window.location.reload(true);
      navigate("/admin/dashboard");
    }
  }, []);

  return (
    <div className="pagesContent">
      <Navbar />
      <div className="mainPage">
        <div className="mainPage-bg"></div>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/app" element={<AllApp />} />
          <Route path="/game" element={<Game />} />
          <Route path="/movedApp" element={<MovedApp />} />
          <Route path="/updateApp" element={<EditApp />} />
          <Route path="/customAd" element={<CustomAd />} />
          <Route path="/shortVideos" element={<ShortVideo />} />
          <Route path="/liveStreamVideo" element={<LiveStreamVideo />} />
          <Route path="/videoCallVideo" element={<VideoCallVideo />} />
          <Route path="/privateVideo" element={<PrivateVideo />} />
          <Route path="/testAdId" element={<TestId />} />
          <Route path="/moreApp" element={<MoreApp />} />
          <Route path="/host" element={<HostTable />} />
          <Route path="/host2" element={<HostTableTwo />} />
          <Route path="/community" element={<Community />} />
          <Route path="/devlopers" element={<Developer />} />
          <Route path="/gift" element={<GiftMain />} />
          <Route path="/post" element={<PostTable />} />
          <Route path="/notification" element={<NotificationPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/banner" element={<Banner />} />
          <Route path="/withdraw" element={<WithdrawRequest />} />
          <Route path="/hostHistory" element={<CallHistory />} />
          <Route path="/userHistory" element={<UserCallHistory />} />
        </Routes>
      </div>
    </div>
  );
}
