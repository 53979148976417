import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCallHistory, getUserCallHistory } from "../../../store/slice/hostSlice";
import Table from "../../extra/Table";
import DateRange from "../../extra/DateRangePicker";
import Button from "../../extra/Button";
import dayjs from "dayjs";
import Pagination from "../../extra/Pagination";
import HistoryIcon from '@mui/icons-material/History';

export const UserCallHistory = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState("All");
    const [endDate, setEndDate] = useState("All");
    const [start, setStart] = useState(1);
    const [limit, setLimit] = useState(10);

    const { userCallData, total } = useSelector((state) => state.hostData);

    const hostId = location?.state?._id;

    useEffect(() => {
        const payload = {
            userId: hostId,
            startDate: startDate,
            endDate: endDate,
            start: start,
            limit: limit,
        };
        dispatch(getUserCallHistory(payload));
    }, [startDate, endDate]);

    const handleAllDate = () => {
        setStartDate("All");
        setEndDate("All");
    };

    const mapData = [
        {
            Header: "Index",
            body: "index",
            Cell: ({ index }) => (
                <span className="  text-nowrap">
                    {(start - 1) * limit + parseInt(index) + 1}
                </span>
            ),
        },
        {
            Header: "Receiver",
            body: "user.name",
            Cell: ({ row }) => (
                <span className="  text-nowrap">
                    {row?.user ? row?.user?.firstName : "-"}
                </span>
            ),
        },
        {
            Header: "Coin",
            body: "coin",
        },
        {
            Header: "Start Time",
            Cell: ({ row }) => (
                <span className="  text-nowrap">
                    {row?.isFake ? "-" : row?.startTime ? row?.startTime : "-"}
                </span>
            ),
        },
        {
            Header: "End Time",
            Cell: ({ row }) => (
                <span className="  text-nowrap">
                    {row?.isFake ? "-" : row?.endTime ? row?.endTime : "-"}
                </span>
            ),
        },
        {
            Header: "Duration (s)",
            body: "duration",
        },
        {
            Header: "Call type ( Fake/Genuine ) ",
            Cell: ({ row }) => (
                <span className="  text-nowrap">
                    {row?.isFake ? "Fake" : "Genuine"}
                </span>
            ),
        },
    ];

    const handlePageChange = (pageNumber) => {
        setStart(pageNumber);
    };

    const handleRowsPerPage = (value) => {
        setStart(1);
        setLimit(value);
    };


    return (
        <div className="appUser">
            <div className="dialog-head d-flex justify-content-between">
                <h4>{`${location?.state?.firstName}'s Call History`}</h4>
            </div>
            <div className="row mt-2 search-input-box">
                <div className="col-12  d-flex ">
                    <Button
                        btnName={"All"}
                        newClass={"all-dateBtn"}
                        onClick={() => handleAllDate()}
                    />
                    <DateRange
                        setEndDate={setEndDate}
                        setStartDate={setStartDate}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
            </div>
            <div>
                <Table
                    data={userCallData}
                    type={"server"}
                    mapData={mapData}
                    start={start}
                    limit={limit}
                />
            </div>
            <Pagination
                type={"server"}
                start={start}
                limit={limit}
                totalData={total}
                setStart={setStart}
                setLimit={setLimit}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
            />
        </div>
    );
};
