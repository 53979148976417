// export const baseURL = "http://192.168.1.27:5010";
// export const key = "dbTmgroachhlJDGGzhRxgDYdKIvmraTs";

// export const baseURL = "http://192.168.1.27:5000";
// export const key = "dbTmgroachhlJDGGzhRxgDYdKIvmraTs";

// export const baseURL = "https://app.hashcrafters.com";
// export const baseURL = "https://admin.rayzi.app";
export const baseURL = "https://testvirtualdesk.codderlab.com";
export const key = "vHlwi52J3p6Qq8gcxVHZJXEvMqrcaVwF";
    