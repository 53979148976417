import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import { appUserGet, hostImageVerifySwitch } from "../../../store/slice/appSlice";
import DateRange from "../../extra/DateRangePicker";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../../extra/Table";
import { baseURL } from "../../utils/config";
import dayjs from "dayjs";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import NoImage from "../../../assets/images/noImage.jpg"
import { openDialog } from "../../../store/slice/dialogueSlice";
import ScreenDialog from "../../dialogComponent/ScreenDialog";
import { getAppwiseHost } from "../../../store/slice/hostSlice";
import HistoryIcon from '@mui/icons-material/History';

const Host = (props) => {
  const {
    appHost
  } = useSelector((state) => state.hostData);

  const { dialogue, dialogueData, dialogueType } = useSelector(
    (state) => state.dialogue
  );

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("All");
  const [endDate, setEndDate] = useState("All");
  const location = useLocation();
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      start: start,
      limit: limit,
      appId: location?.state?.editAppData?._id,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(getAppwiseHost(payload));
  }, [startDate, endDate, location?.state, limit]);

  useEffect(() => {
    setData(appHost);
  }, [appHost]);


  const handleShowScreen = (row) => {
    const payload = {
      dialogue: true,
      dialogueType: "allScreens",
      dialogueData: row,
    };
    dispatch(openDialog(payload));

  }

  const handleImageVerify = (row, status) => {

    const payload = {
      userId: row?._id,
      appId: row?.appId,
      hostImageIsVerify: status,
    };
    props.hostImageVerifySwitch(payload);
  }

  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Profile Image",
      body: "profileImage",
      Cell: ({ row }) => (
        <div className="logo-image">
          <img
            src={row?.profileImage ? baseURL + "/" + row?.profileImage : NoImage}
            alt="logo"
            style={{
              height: "50px",
              width: "50px",
              borderRadius: "7px",
              objectFit: "cover",
              border: "1px solid #e8e8e8",
              boxShadow: "0 0 1px #000000"
            }}
          />
        </div>
      ),
    },
    {
      Header: "Host Image",
      body: "profileImage",
      Cell: ({ row }) => (
        <div className="logo-image">
          <img
            src={row?.hostImage ? baseURL + "/" + row?.hostImage : NoImage}
            alt="logo"
            style={{
              height: "50px",
              width: "50px",
              borderRadius: "7px",
              objectFit: "cover",
              border: "1px solid #e8e8e8",
              boxShadow: "0 0 1px #000000"
            }}
          />
        </div>
      ),
    },


    {
      Header: "Name",
      body: "name",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.firstName ? row?.firstName : "-"}
        </span>
      ),
    },
    {
      Header: "Gender",
      body: "gender",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.gender ? row?.gender : "-"}
        </span>
      ),
    },
    {
      Header: "Email",
      body: "email",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.email ? row?.email : "-"}</span>
      ),
    },
    {
      Header: "At Screen",
      body: "atScreen",
      Cell: ({ row }) => (
        <span className="  text-nowrap cursor-pointer" onClick={() => handleShowScreen(row)}>{row?.atScreen ? row?.atScreen : "-"}</span>
      ),
    },
    {
      Header: "Country",
      body: "country",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.country ? row?.country : "-"}
        </span>
      ),
    },
    {
      Header: "Status",
      body: "status",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.hostImageIsVerify ?
            <Button
              btnName={`${row?.hostImageIsVerify === 1 ? "Pending" : row?.hostImageIsVerify === 2 ? 'Verified' : row?.hostImageIsVerify === 3 ? "Rejected" : "-"}`}
              type={"button"}

              newClass={` ${row?.hostImageIsVerify === 1 ? "new-btn" : row?.hostImageIsVerify === 2 ? 'male-btn' : row?.hostImageIsVerify === 3 ? "cancel-btn" : ""}`}
            /> : "-"
          }
        </span>
      ),
    },
    {
      Header: "Image Verify",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.gender === "female" ?

            <Button
              btnName="Verify"
              type={"button"}
              onClick={() => handleImageVerify(row, 2)}
              newClass={"male-btn"}
            /> : "-"
          }
        </span>
      ),
    },
    {
      Header: "Image Decline",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.gender === "female" ?
            <Button
              btnName="Decline"
              type={"button"}
              newClass={"cancel-btn"}
              onClick={() => handleImageVerify(row, 3)}

            />
            : "-"}
        </span>
      ),
    },

    {
      Header: "History",
      Cell: ({ row }) => (
        <span className="  text-nowrap cursor-pointer">

          <HistoryIcon className="history-icon" fontSize="large" onClick={() => handleHostHistory(row)} />



        </span>
      ),
    },

    {
      Header: "Created At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.createdAt && dayjs(row?.createdAt)?.format("DD MMM YYYY")}
        </span>
      ),
    },
    {
      Header: "Last login",
      body: "lastLogin",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.lastLogin}
        </span>
      ),
    },
  ];

  const handleHostHistory = (data) => {
    navigate("/admin/hostHistory", { state: data });
  }


  const openWithdrawPage = (data) => {

  }

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleAllDate = () => {
    setStartDate("All");
    setEndDate("All");
  };
  return (
    <div className="appUser">
      {dialogueType === "allScreens" &&
        <ScreenDialog />}
      {/* <div className="row">
        <div className="col-12 col-sm-2 mb-2 mb-md-0">
          <div className="totalUser-box">
            <h6>Total User : {appTotalUsers ? appTotalUsers : "0"}</h6>
          </div>
        </div>
        <div className="col-12 col-sm-10 d-flex justify-content-end">
          <div className="totalUser-box">
            <h6>Total Analytic User : {appTotalUser ? appTotalUser : "0"}</h6>
          </div>
          <div className="totalUser-box">
            <h6>New User : {appNewUser ? appNewUser : "0"}</h6>
          </div>
          <div className="totalUser-box">
            <h6>Organic User : {appDirectUser ? appDirectUser : "0"}</h6>
          </div>
          <div className="totalUser-box">
            <h6>Paid User: {appMarketThrough ? appMarketThrough : "0"}</h6>
          </div>
        </div>
      </div> */}
      <div className="row mt-2 search-input-box">
        <div className="col-12 col-sm-6">
          <Searching
            placeHolder={"What’re you searching for?"}
            type="client"
            buttonShow={true}
            data={appHost}
            setData={setData}
            onFilterData={handleFilterData}
            searchValue={search}
          />
        </div>
        <div className="col-12 col-sm-6 d-flex justify-content-center align-items-center justify-content-sm-end">
          <Button
            btnName={"All"}
            newClass={"all-dateBtn"}
            onClick={() => handleAllDate()}
          />
          <DateRange
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>
      <div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
        //   start={start}
        //   limit={limit}
        />
        {/* <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={appTotalUser}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        /> */}
      </div>
    </div>
  );
};
export default connect(null, {
  appUserGet, hostImageVerifySwitch
})(Host);
